import LandingStep from "@/component/steps/LandingStep";
import { useBVNContext } from "@/context/BVNContext";
import useStateRefresh from "@/hooks/useStateRefresh";
import HeroImg from "@/assets/BVNHeaderImage.png";
import { MobileBackButton } from "@/component/fragments/BackButton";
import EnterBVNStep from "@/component/steps/EnterVerificationNumStep";
import TipsForVideo from "@/component/steps/TipsForVideo";
import LivenessCheck from "@/component/steps/LivenessCheck";
import ConfirmImageCapture from "@/component/steps/ConfirmImageCapture";
import VerificationCompleted from "@/component/steps/VerificationCompleted";
import { BVNCopy, CreateStepErrorMsg } from "@/constants";
import { useDisplayContext } from "@/context/DisplayContext";
import { useFlowContext } from "@/context/FlowContext";
import { cn } from "@/utils";

const BVNMobile = () => {
  const { dispatch, state } = useBVNContext();

  const contentLoaded = useStateRefresh({
    effect: () => dispatch({ type: "advance", step: 1 }),
  });

  const { dispatch: displayDispatch } = useDisplayContext();

  const { flowState } = useFlowContext();

  const ninMode = Boolean(flowState.ninOrBVN);

  const { step } = state;

  const renderSteps = (): React.ReactNode => {
    switch (step) {
      case 1:
        return (
          <LandingStep
            heroImg={HeroImg}
            onNext={() => dispatch({ type: "advance" })}
            title="Verify Bank Verification Number (BVN) "
            infoCopy={BVNCopy}
          />
        );

      case 2:
        return (
          <EnterBVNStep
            title={!ninMode ? "Enter your BVN" : "Confirm your BVN"}
            desc="We need your BVN so you can get verified on Raven bank"
            hintText={
              <p>
                Dial <span className="accent">*565*0#</span> to get your BVN Number, must be with
                the registered phone number.
              </p>
            }
            inputLabel={!ninMode ? "Enter BVN" : "BVN"}
            onAdvance={(bvn) => {
              if (state.file) {
                dispatch({ type: "*", payload: { error: undefined, bvn, step: 3 } });
              } else {
                dispatch({ type: "addBVN", payload: bvn });
              }
            }}
            inputValue={state.bvn}
            error={state.error}
          />
        );
      case 3:
        return (
          <TipsForVideo
            onNext={() => dispatch({ type: "advance" })}
            onVerifyWithPhone={() => dispatch({ step: 100, type: "advance" })}
          />
        );
      case 4:
        return (
          <LivenessCheck
            type="bvn"
            value={state.bvn}
            onCapture={(src) => dispatch({ type: "addImage", payload: src })}
            onBack={() => dispatch({ type: "previous" })}
          />
        );
      case 5:
        return (
          <ConfirmImageCapture
            state={state}
            onComplete={() => dispatch({ type: "advance" })}
            onRetakeSelfie={() => dispatch({ type: "previous" })}
            type="bvn"
            onFetchError={(err) =>
              dispatch({ type: "onFetchError", payload: { error: err, redirectTo: 2 } })
            }
          />
        );
      case 6:
        return <VerificationCompleted onClick={() => displayDispatch({ type: "closeModal" })} />;
      default:
        throw new Error(CreateStepErrorMsg(step));
    }
  };

  return (
    <section className={cn("v-step v-step__sm", step > 1 && "mobile-back-button-present")}>
      {contentLoaded && (
        <>
          {step > 1 && step < 6 && (
            <MobileBackButton onClick={() => dispatch({ type: "previous" })} />
          )}
          <div className={cn("mobile-wrapper", step > 1 && step < 6 && "mobile-offset-top")}>
            {renderSteps()}
            {/* {step === 4 && <BVNNotAllowedRow data={BVNDisallowedList} />} */}
          </div>
        </>
      )}
    </section>
  );
};

export default BVNMobile;
