import { useFlowContext } from "@/context/FlowContext";
import { assertRef, assertVerificationMode, log } from "@/utils";
import { RefAPI } from "@/utils/axios";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

interface Args {
  /**
   * set to true if you wish to maintain old url
   */
  updateVerificationMode: boolean;
  onError(error: string): void;
}

const assertNINorBVN = (value: unknown) => {
  if (value && value !== "undefined") {
    return value as string;
  }
};

const useGetReference = (args?: Partial<Args>) => {
  const { setFlowState } = useFlowContext();
  const [loading, setLoading] = useState(true);

  const [query] = useSearchParams();

  const ref = query.get("reference");
  const product = query.get("origin");

  const ninOrBVN = query.get("ninOrBvn");

  const handleRefGet = useCallback(async () => {
    const updateMode = args?.updateVerificationMode ?? false;

    if (product && ["personal", "bankbox", "redpay"].includes(product)) {
      setFlowState((old) => ({ ...old, ninOrBVN: assertNINorBVN(ninOrBVN) }));
      return setLoading(false);
    }

    try {
      /**
       * https://central.getraventest.com/get_verification_detail?reference=1296725023647088
       *
       */
      const res = await RefAPI.get<APIResponse<GetVerificationDetail>>(
        `/get_verification_detail?reference=${assertRef(ref)}`
      );

      if (res.data.status === "success") {
        const verificationType = assertVerificationMode(res.data.data.verification.type);

        setFlowState((old) => ({
          ...old,
          mode: updateMode ? old.mode : verificationType,
          metaData: res.data.data,
          ninOrBVN: assertNINorBVN(ninOrBVN),
        }));
      }
    } catch (error) {
      log(error);
      if (args?.onError) {
        args.onError(String(error));
      }
    } finally {
      setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [args?.updateVerificationMode, ref, ninOrBVN, setFlowState, product]);

  useEffect(() => {
    handleRefGet();
  }, [handleRefGet]);

  return loading;
};

export default useGetReference;
