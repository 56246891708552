import { useFlowContext } from "@/context/FlowContext";
import { cn, getQueryVariable } from "@/utils";
import { RavenButton } from "@ravenpay/raven-bank-ui";

type ButtonProps = React.ComponentProps<typeof RavenButton>;

type Props = Omit<ButtonProps, "color"> & {
  color?: ButtonProps["color"];
  children?: any;
  fitContent?: true;
  outline?: boolean;
  danger?: boolean;
};

const Button = (prop: Props) => {
  const { color, className, fitContent, outline, danger, ...props } = prop;
  const mode = getQueryVariable("origin");
  const { flowState } = useFlowContext();

  return (
    <RavenButton
      {...props}
      color={color ?? flowState.color ?? "purple-light"}
      className={cn(className, !fitContent && "w--full", outline && "btn-outline")}
      style={{ backgroundColor: mode === "redpay" && !outline ? "rgba(191, 0, 16, 1)" : undefined }}
    />
  );
};

export default Button;
