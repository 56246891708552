import IDVerificationDesktop from "@/component/flows/IdentityVerification.tsx/Desktop";
import IDVerificationMobile from "@/component/flows/IdentityVerification.tsx/Mobile";
import HideContent from "@/component/fragments/HideContent";
import "~/flows/IdVerification.scss";
import BorderFrame from "@/assets/BorderFrames.png";
import HeaderImage from "@/assets/HeaderImage.png";
import Success from "@/assets/VST-Success.png";
import useIsMobile from "@/hooks/useIsMobile";

const images = [BorderFrame, HeaderImage, Success];

const IDVerification = () => {
  const isMobile = useIsMobile();

  return (
    <div className="verification">
      <div className="verification-wrapper identity-verification">
        {isMobile ? <IDVerificationMobile /> : <IDVerificationDesktop />}
      </div>
      <HideContent>
        {images.map((src) => (
          <img key={src} src={src} alt="" />
        ))}
      </HideContent>
    </div>
  );
};

export default IDVerification;
