import React from "react";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import awsexports from "./aws-exports";
import { Theme, ThemeProvider, useTheme } from "@aws-amplify/ui-react";

Amplify.configure(awsexports);

const FontFamily =
  "'br_firma', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', sans-serif";

export const AWSWrapper = ({ children }: IChildren) => {
  const theme: Theme = {
    name: "Raven Verification SDK",
    tokens: {
      fonts: {
        default: {
          variable: { value: FontFamily },
          static: { value: FontFamily },
        },
      },
    },
  };

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
