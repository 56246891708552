import { createContext, useContext, useReducer } from "react";

const initialState: DisplayState = {
  display: "app",
  showModal: true,
};
type Action =
  | { type: "closeModal" | "onCloseClick" | "onContinue" | "onVerify" | "closeErrorModal" }
  | { type: "onError"; payload: string };

const DisplayContext = createContext(initialState);

type TDisplayUpdater = React.Dispatch<Action>;

const DisplayUpdater = createContext<TDisplayUpdater>((_display) => {
  throw new Error("Dispatch handler for display state was used before it was initialized");
});

const displayReducer = (state: DisplayState, action: Action): DisplayState => {
  switch (action.type) {
    case "onCloseClick":
      return { ...state, display: "close", showModal: false };
    case "onContinue":
      return { ...state, display: "app", showModal: true };
    case "onError":
      return { ...state, display: "error", errorMessage: action.payload };
    case "onVerify":
      window.parent.postMessage({ type: "onVerify" }, "*");
      return { ...state, display: "app", showModal: false };
    case "closeModal":
      window.parent.postMessage({ type: "closeModal" }, "*");
      return { ...state, display: "app", showModal: false };
    case "closeErrorModal":
      window.parent.postMessage({ type: "closeModal" }, "*");
      /** Implement this */
      return { ...state, display: "null", showModal: false };
  }
};

/**
 * controls what the user is seeing per time
 */
export const DisplayProvider = ({ children }: IChildren) => {
  const [display, dispatch] = useReducer(displayReducer, initialState);

  return (
    <DisplayContext.Provider value={display}>
      <DisplayUpdater.Provider value={dispatch}>{children}</DisplayUpdater.Provider>
    </DisplayContext.Provider>
  );
};

export const useDisplayContext = () => {
  const displayState = useContext(DisplayContext);
  const dispatch = useContext(DisplayUpdater);

  const assertDisplay = (view: AppDisplayMode) => {
    return displayState.display === view;
  };

  return { displayState, dispatch, assertDisplay };
};
