import InfoBox, { InfoBoxProps } from "@/component/fragments/InfoBox";

interface Props {
  infoData: InfoBoxProps[];
}

const InfoSection = ({ infoData }: Props) => (
  <div className="verification-info-section">
    {infoData.map((ele) => (
      <InfoBox {...ele} key={ele.title} />
    ))}
  </div>
);

export default InfoSection;
