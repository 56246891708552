import BVNDesktop from "@/component/flows/BVN/BVNDesktop";
import BVNMobile from "@/component/flows/BVN/BVNMobile";
import useIsMobile from "@/hooks/useIsMobile";
import "~/flows/BVNFlow.scss";
import "~/flows/IdVerification.scss";

const BVNVerification = () => {
  const isMobile = useIsMobile();

  return (
    <div className="verification bvn-flow">
      <div className="verification-wrapper bvn-verification">
        {isMobile ? <BVNMobile /> : <BVNDesktop />}
      </div>
    </div>
  );
};

export default BVNVerification;
