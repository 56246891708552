import type { InfoBoxProps } from "@/component/fragments/InfoBox";
import type { BVNNotAllowedProps } from "@/component/fragments/NotAllowed";

export const AcceptedImages = [
  "image/apng",
  "image/avif",
  "image/jpeg",
  "image/png",
  "image/svg+xml",
  "image/webp",
  // "image/gif",
];

export const CreateStepErrorMsg = (step: number) =>
  `Unreachable action context reached for: Step ${step}, please provide matching case`;

export const IDCopy: InfoBoxProps[] = [
  {
    desc: "We cross-reference the provided NIN with government databases to ensure data accuracy and authenticity.",
    title: "How we verify you",
    icon: "InfoCircle",
  },
  {
    icon: "Zap",
    title: "Fast and secure",
    desc: "You don't have to wait long to get verified, in less than few second your details would be verified.",
  },
];

export const BVNCopy: InfoBoxProps[] = [
  {
    desc: "We cross-reference the provided BVN with government databases to ensure data accuracy and authenticity.",
    title: "How we verify you",
    icon: "InfoCircle",
  },
  {
    icon: "Zap",
    title: "Fast and secure",
    desc: "You don't have to wait long to get verified, in less than few second your details would be verified.",
  },
];

export const BVNDisallowedList: BVNNotAllowedProps[] = [
  { text: "Ensure Good Lighting and Focus", icon: "LightBulb" },
  { text: "Avoid wearing sunshades, face mask e.t.c", icon: "GlassesII" },
  { text: "Review and Verify selfie Before Submission", icon: "PhotoAlbum" },
];

export const IsDevMode = process.env.NODE_ENV === "development";
