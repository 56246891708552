import BVNPage from "@/pages/BVNVerification";
import IDPage from "@/pages/IDVerification";
import InitPage from "@/pages/InitPage";

interface Route {
  path: string;
  component: () => JSX.Element;
}

export const AllRoutes: Route[] = [
  { path: "/bvn", component: BVNPage },
  { path: "/nin", component: IDPage },
  { path: "/", component: InitPage },
];
