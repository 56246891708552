import { useEffect, useState } from "react";

interface Args {
  effect: Function;
}

const useStateRefresh = (args?: Partial<Args>) => {
  const [domReady, setDomReady] = useState(false);

  /** reset the state if navigate mobile */
  useEffect(() => {
    const handleStateRefresh = () => {
      if (args && args.effect) {
        args.effect();
      }
      setDomReady(true);
    };

    handleStateRefresh();

    window.addEventListener("load", handleStateRefresh);

    return () => {
      window.removeEventListener("load", handleStateRefresh);
    };
  }, []);

  return domReady;
};

export default useStateRefresh;
