import Icons from "@/assets/Icons";
import Button from "@/component/fragments/Button";
import { BVNNotAllowedRow } from "@/component/fragments/NotAllowed";
import { BVNDisallowedList } from "@/constants";
import useIsMobile from "@/hooks/useIsMobile";

interface Props {
  onNext(): void;
  onVerifyWithPhone(): void;
}

/**
 * ```tsx
 * <TipsForVideo
 *   onNext={() => dispatch({ type: "advance" })}
 *   onVerifyWithPhone={() => dispatch({ step: 100, type: "advance" })}
 * />
 * ```
 */
const TipsForVideo = ({ onNext, onVerifyWithPhone }: Props) => {
  const isMobile = useIsMobile();

  return (
    <div className="bvn-liveness-check space-y-20">
      <div className="verification-header">
        <h2 className="verification-title">Perform liveliness check</h2>
        <p>Please take a clear picture of yourself and show us your lovely smile</p>
      </div>
      <div>
        <h4 style={{ marginBottom: "1.5rem", paddingTop: "0.5rem" }} className="liveness-header">
          Tips for liveliness check
        </h4>
        <BVNNotAllowedRow data={BVNDisallowedList} />
      </div>
      <div style={{ gap: "8px", paddingTop: "2rem" }} className="two-grid__buttons">
        <Button onClick={onNext}>Get Started</Button>
        {!isMobile && (
          <>
            {/* <div className="centered-x">Or</div> */}
            <Button onClick={onVerifyWithPhone} outline>
              <figure className="switch-to-mobile-illustration">{Icons.PhoneII}</figure>
              <span>Verify with phone</span>
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default TipsForVideo;
