import Step1 from "@/component/steps/LandingStep";
import VerificationCompleted from "@/component/steps/VerificationCompleted";
import SwapToPhone from "@/component/steps/SwapToPhone";
import { CreateStepErrorMsg, IDCopy } from "@/constants";
import { useIDContext } from "@/context/IDContext";
import { RavenModal } from "@ravenpay/raven-bank-ui";
import HeroImg from "@/assets/HeaderImage.png";
import useStateRefresh from "@/hooks/useStateRefresh";
// import { DesktopBackButton } from "@/component/fragments/BackButton";
import EnterVerificationNumStep from "@/component/steps/EnterVerificationNumStep";
import TipsForVideo from "@/component/steps/TipsForVideo";
import LivenessCheck from "@/component/steps/LivenessCheck";
import ConfirmImageCapture from "@/component/steps/ConfirmImageCapture";
import { log } from "@/utils";
import { useDisplayContext } from "@/context/DisplayContext";
import { useFlowContext } from "@/context/FlowContext";

const IDVerificationDesktop = () => {
  const { dispatch, state } = useIDContext();
  const { dispatch: displayDispatch, displayState } = useDisplayContext();

  const { showModal } = displayState;

  const { flowState } = useFlowContext();

  const ninMode = Boolean(flowState.ninOrBVN);

  const contentLoaded = useStateRefresh({
    effect: () => dispatch({ type: "advance", step: 1 }),
  });

  log(state);

  const { step } = state;

  const errorMsg = CreateStepErrorMsg(step);

  const renderSteps = () => {
    switch (step) {
      case 1:
        return (
          <Step1
            heroImg={HeroImg}
            title="Verify your National identification Number( NIN)"
            infoCopy={IDCopy}
          />
        );
      case 2:
        return (
          <EnterVerificationNumStep
            title={!ninMode ? "Enter your NIN" : "Confirm your NIN"}
            desc="We need your NIN so you can get verified on Raven bank"
            hintText={
              <p>
                Dial <span className="accent"> *346#</span> to get your NIN Number, must be with the
                registered phone number.
              </p>
            }
            inputLabel={!ninMode ? "Enter NIN" : "NIN"}
            onAdvance={(nin) => {
              if (state.file) {
                dispatch({ type: "*", payload: { error: undefined, nin, step: 3 } });
              } else {
                dispatch({ type: "addNIN", payload: nin });
              }
            }}
            inputValue={state.nin}
            error={state.error}
          />
        );
      case 3:
        return (
          <TipsForVideo
            onNext={() => dispatch({ type: "advance" })}
            onVerifyWithPhone={() => dispatch({ step: 100, type: "advance" })}
          />
        );
      case 4:
        return (
          <LivenessCheck
            type="nin"
            value={state.nin}
            onCapture={(src) => dispatch({ type: "addImage", payload: src })}
            onBack={() => dispatch({ type: "previous" })}
          />
        );
      case 5:
        return (
          <ConfirmImageCapture
            state={state}
            onComplete={() => dispatch({ type: "advance" })}
            onRetakeSelfie={() => dispatch({ type: "previous" })}
            type="nin"
            onFetchError={(err) =>
              dispatch({ type: "onFetchError", payload: { error: err, redirectTo: 2 } })
            }
          />
        );
      case 6:
        return <VerificationCompleted onClick={() => displayDispatch({ type: "closeModal" })} />;
      case 100:
        return <SwapToPhone />;
      default:
        throw new Error(errorMsg);
    }
  };

  return (
    <div className="v-step v-step__modal">
      {contentLoaded && (
        <RavenModal
          onClose={() =>
            step < 6
              ? displayDispatch({ type: "onCloseClick" })
              : displayDispatch({ type: "closeModal" })
          }
          visble={showModal}
          closeColor
          className="verification-modal"
          onBtnClick={() => {}}
          outerClose
        >
          {/* {step > 1 && step < 6 && (
            <DesktopBackButton onClick={() => dispatch({ type: "previous" })} />
          )} */}
          <div className="verification__content">{renderSteps()}</div>
        </RavenModal>
      )}
    </div>
  );
};

export default IDVerificationDesktop;
