import { assertRef, dataUrlToFile } from ".";
import { API } from "./axios";

interface MatchBulkPayload {
  match: boolean;
  similarityScore: number;
}

export class VerificationAPI {
  private static assertMetaData = (metaData: unknown, ref?: SNN) => {
    if (metaData) {
      if (typeof metaData === "string") {
        const metaObj = JSON.parse(metaData);

        return { ...metaObj, reference: assertRef(ref) };
      }

      return { ...metaData, reference: assertRef(ref) };
    }

    return { reference: assertRef(ref) };
  };

  private static matchBulkImages = async (images: string[]) => {
    const fileImages = images.map((ele, idx) => dataUrlToFile(ele, `user-image-${idx}`));

    const payload = new FormData();

    fileImages.forEach((src) => {
      if (src) {
        payload.append("images", src);
      }
    });

    const response = await API.post<APIResponse<MatchBulkPayload>>("/images/match_bulk", payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data.data;
  };

  static getSessionId = async () => {
    const res = await API.post("/kyc/start_liveness_session");
    return res.data;
  };

  static getLivenessScore = async (sessionId: string) => {
    const url = `/kyc/get_liveness_session_result?session_id=${sessionId}`;
    const res = await API.get<APIResponse<GetLivenessScoreResponse>>(url);

    return res.data;
  };

  static sendLivenessReport = async (payload: any, type: "bvn" | "nin") => {
    const res = await API.post<APIResponse<VerificationCompleted>>(
      `/${type}/initiate_${type}_verification_liveness`,
      payload
    );
    return res;
  };
}
