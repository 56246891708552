import { createContext, useContext, useReducer } from "react";

const initialState: IDVerificationState = {
  nin: null,
  file: null,
  step: 1,
};

type Action =
  | { type: "advance"; step?: number }
  | { type: "previous" }
  | { type: "addNIN"; payload: string; step?: number }
  | { type: "addImage"; payload: string }
  | { type: "skipToPhone" }
  | { type: "onFetchError"; payload: OnFetchErrorPayload }
  | {
      /** use this to randomly update any state without creating a custom type */
      type: "*";
      payload: Partial<IDVerificationState>;
    };

const verificationReducer = (state: IDVerificationState, action: Action): IDVerificationState => {
  const step = state.step + 1;

  switch (action.type) {
    case "advance":
      return { ...state, step: action?.step ?? step };
    case "previous":
      return { ...state, step: state.step - 1 };
    case "addNIN":
      return { ...state, nin: action.payload, step: action?.step ?? step, error: undefined };
    case "addImage":
      return { ...state, file: action.payload, step };
    case "onFetchError":
      return {
        ...state,
        error: action.payload?.error,
        step: action.payload.redirectTo,
      };
    case "*":
      return { ...state, ...action.payload };
    case "skipToPhone":
      return { ...state, step: 100 };
  }
};

const StateContext = createContext(initialState);
const DispatchContext = createContext<React.Dispatch<Action>>((action: Action) => {
  /** prevent dispatch context from being called if provider has not being initialized */
  throw new Error("Context value called before IDProvider was initialized");
});

/**
 * @see https://react.dev/learn/scaling-up-with-reducer-and-context
 */
export const IDProvider = ({ children }: IChildren) => {
  const [state, dispatch] = useReducer(verificationReducer, initialState);

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  );
};

export const useIDContext = () => {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);

  return { state, dispatch };
};
