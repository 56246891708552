import { RavenToast } from "@ravenpay/raven-bank-ui";
import IDVerification from "./IDVerification";
import { useFlowContext } from "../context/FlowContext";
import BorderFrame from "@/assets/BorderFrames.png";
import HeaderImage from "@/assets/HeaderImage.png";
import Success from "@/assets/VST-Success.png";
import HideContent from "../component/fragments/HideContent";
import BVNVerification from "./BVNVerification";
import { Fragment } from "react";

const images = [BorderFrame, HeaderImage, Success];

const InitPage = () => {
  const { flowState } = useFlowContext();

  const renderMode = () => {
    switch (flowState.mode) {
      case "bvn":
        return <BVNVerification />;
      case "nin":
        return <IDVerification />;
    }
  };

  return (
    <Fragment>
      {renderMode()}
      <RavenToast />
      <HideContent>
        {images.map((src) => (
          <img key={src} src={src} alt="" />
        ))}
      </HideContent>
    </Fragment>
  );
};

export default InitPage;
