import Icons from "@/assets/Icons";
import Button from "@/component/fragments/Button";
import { useFlowContext } from "@/context/FlowContext";
import { removeSpace } from "@/utils";
import { RavenInputField } from "@ravenpay/raven-bank-ui";
import { useState } from "react";
import "~/steps/EnterVerificationNumStep.scss";

const IDLength = 11;

interface Props {
  title: string;
  desc: string;
  inputLabel: string;
  hintText: React.ReactNode;
  onAdvance: (value: string) => void;
  btnLabel?: string;
  btnLoadState?: boolean;
  inputValue: string | null;
  error: string | undefined;
}

const EnterVerificationNumStep = (props: Props) => {
  const {
    desc,
    hintText,
    inputLabel,
    title,
    onAdvance,
    btnLabel,
    btnLoadState,
    inputValue,
    error,
  } = props;

  const { flowState } = useFlowContext();

  const [value, setValue] = useState(flowState.ninOrBVN ?? inputValue ?? "");

  const [showError, setShowError] = useState(Boolean(error));

  return (
    <div className="bvn-two space-y-20">
      <div className="verification-header">
        <h2 className="verification-title">{title}</h2>
        <p>{desc}</p>
      </div>
      <div className="bvn-two__content">
        <RavenInputField
          type="account-number"
          color={flowState.color ?? "purple-light"}
          label={inputLabel}
          placeholder="01234567899"
          value={removeSpace(value)}
          onChange={(e: any) => {
            setValue(removeSpace(e.target.value));
            setShowError(false);
          }}
          maxLength={IDLength}
          labelColor="purple-light"
          showError={showError}
          errorText={error}
          disabled={Boolean(flowState.ninOrBVN)}
          
        />
        <div className="bvn-hint">
          <div className="hint-icon">{Icons.HintCircle}</div>
          {hintText}
        </div>
      </div>
      <div className="footer-wrap">
        <Button
          onClick={() => onAdvance(value)}
          disabled={value.length < IDLength}
          label={btnLabel ?? "Continue to verify"}
          loading={btnLoadState}
        />
      </div>
    </div>
  );
};

export default EnterVerificationNumStep;
