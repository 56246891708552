import Icons from "@/assets/Icons";
import { cn } from "@/utils";
import "~/fragments/InfoBox.scss";

export interface InfoBoxProps extends IClassName {
  icon: keyof typeof Icons;
  title: string;
  desc: string;
}

const InfoBox = ({ className, icon, desc, title }: InfoBoxProps) => (
  <div className={cn("info-box", className)}>
    <div className="info-box__icon">{Icons[icon]}</div>
    <div className="info-box__content">
      <h4 className="info-box__title">{title}</h4>
      <p>{desc}</p>
    </div>
  </div>
);

export default InfoBox;
