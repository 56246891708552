import { AWSLivenessLibrary, AWSLivenessLibraryProps } from "@/packages/aws-liveness";

const LivenessCheck = (props: AWSLivenessLibraryProps) => {
  return (
    <div style={{ paddingTop: "0.4rem" }} className="bvn-liveness-check space-y-40">
      {false && (
        <div className="verification-header text-center">
          <p className="fz-base">
            Position your phone at eye level and ensure that your face fits within the oval shape to
            begin the process
          </p>
        </div>
      )}

      <div className="aws-liveness-rvp">
        <AWSLivenessLibrary {...props} />
      </div>
    </div>
  );
};

export default LivenessCheck;
