import QRCode from "react-qr-code";
import Spinner from "../fragments/Spinner";
import { useFlowContext } from "@/context/FlowContext";

const SwapToPhone = () => {
  const { flowState } = useFlowContext();

  const { mobileCallBack } = flowState;

  return (
    <div className="vst-phone text-center space-y-40">
      <div className="verification-header">
        <h2 className="verification-title">Continue verification on your phone</h2>
        <p>Scan this QR code using your phone's camera</p>
      </div>
      <div className="vst-qr">
        <QRCode
          size={256}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={mobileCallBack ?? window.location.href}
          viewBox="0 0 256 256"
        />
      </div>
      <div className="text-center">
        <Spinner />
        <p className="mt-12">Finish this verification on your phone</p>
      </div>
    </div>
  );
};

export default SwapToPhone;
