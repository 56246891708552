import Icons from "@/assets/Icons";
import "~/fragments/BVNAllowed.scss";

export interface BVNNotAllowedProps {
  icon: keyof typeof Icons;
  text: string;
}

export const BVNNotAllowed = ({ icon, text }: BVNNotAllowedProps) => (
  <div className="bvn-disallowed">
    <div className="bvn-disallowed__icons">
      <figure className="bvn-disallowed-main-icon">{Icons[icon]}</figure>
    </div>
    <h4 className="bvn-disallowed-text">{text}</h4>
  </div>
);

interface Props {
  data: BVNNotAllowedProps[];
}
export const BVNNotAllowedRow = ({ data }: Props) => (
  <section className="bvn-allowed-section">
    {data.map((ele) => (
      <BVNNotAllowed key={ele.text} {...ele} />
    ))}
  </section>
);
