import LandingStep from "@/component/steps/LandingStep";
import { CreateStepErrorMsg } from "@/constants";
import { useIDContext } from "@/context/IDContext";
import { cn } from "@/utils";
import { toast } from "@ravenpay/raven-bank-ui";
import { Fragment } from "react";
import { IDCopy } from "@/constants";
import HeroImg from "@/assets/HeaderImage.png";
import useStateRefresh from "@/hooks/useStateRefresh";
import { MobileBackButton } from "@/component/fragments/BackButton";
import EnterVerificationNumStep from "@/component/steps/EnterVerificationNumStep";
import VerificationCompleted from "@/component/steps/VerificationCompleted";
import TipsForVideo from "@/component/steps/TipsForVideo";
import LivenessCheck from "@/component/steps/LivenessCheck";
import ConfirmImageCapture from "@/component/steps/ConfirmImageCapture";
import { useDisplayContext } from "@/context/DisplayContext";
import { useFlowContext } from "@/context/FlowContext";

const IDVerificationMobile = () => {
  const { state, dispatch } = useIDContext();
  const contentLoaded = useStateRefresh({
    effect: () => dispatch({ type: "advance", step: 1 }),
  });

  const { step } = state;
  const errorMsg = CreateStepErrorMsg(step);

  const { flowState } = useFlowContext();

  const ninMode = Boolean(flowState.ninOrBVN);

  const { dispatch: displayDispatch } = useDisplayContext();

  /**
   * https://www.digitalocean.com/community/tutorials/front-and-rear-camera-access-with-javascripts-getusermedia
   */
  const handleStep1Click = () => {
    if ("mediaDevices" in navigator && "getUserMedia" in navigator.mediaDevices) {
      dispatch({ type: "advance" });
    } else {
      toast.error("Your device does not support camera access");
    }
  };

  const renderSteps = () => {
    switch (step) {
      case 1:
        return (
          <LandingStep
            title="Verify your National identification Number( NIN)"
            onNext={handleStep1Click}
            infoCopy={IDCopy}
            heroImg={HeroImg}
          />
        );
      case 2:
        return (
          <EnterVerificationNumStep
            title={!ninMode ? "Enter your NIN" : "Confirm your NIN"}
            desc="We need your NIN so you can get verified on Raven bank"
            hintText={
              <p>
                Dial <span className="accent"> *346#</span> to get your NIN Number, must be with the
                registered phone number.
              </p>
            }
            inputLabel={!ninMode ? "Enter NIN" : "NIN"}
            onAdvance={(nin) => {
              if (state.file) {
                dispatch({ type: "*", payload: { error: undefined, nin, step: 3 } });
              } else {
                dispatch({ type: "addNIN", payload: nin });
              }
            }}
            inputValue={state.nin}
            error={state.error}
          />
        );
      case 3:
        return (
          <TipsForVideo
            onNext={() => dispatch({ type: "advance" })}
            onVerifyWithPhone={() => dispatch({ step: 100, type: "advance" })}
          />
        );
      case 4:
        return (
          <LivenessCheck
            type="nin"
            value={state.nin}
            onCapture={(src) => dispatch({ type: "addImage", payload: src })}
            onBack={() => dispatch({ type: "previous" })}
          />
        );
      case 5:
        return (
          <ConfirmImageCapture
            state={state}
            onComplete={() => dispatch({ type: "advance" })}
            onRetakeSelfie={() => dispatch({ type: "previous" })}
            type="nin"
            onFetchError={(err) =>
              dispatch({ type: "onFetchError", payload: { error: err, redirectTo: 2 } })
            }
          />
        );
      case 6:
        return <VerificationCompleted onClick={() => displayDispatch({ type: "closeModal" })} />;
      default:
        throw new Error(errorMsg);
    }
  };

  return (
    <div className="v-step v-step__sm">
      {contentLoaded && (
        <Fragment>
          {step > 1 && step < 6 && (
            <MobileBackButton onClick={() => dispatch({ type: "previous" })} />
          )}
          <div className={cn("mobile-wrapper", step > 1 && step < 6 && "mobile-offset-top")}>
            {renderSteps()}
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default IDVerificationMobile;
