import "~/fragments/Spinner.scss";

interface Props {
  size?: string;
  loaderSize?: string;
  color?: string;
}

const Spinner = (props: Props) => {
  const { size = "44px", loaderSize = "4px", color = "#755AE2" } = props;

  return (
    <div
      style={
        {
          "--lds-size": size,
          "--lds-loader-size": loaderSize,
          "--lds-spinner-color": color,
        } as React.CSSProperties
      }
      className="loader"
    ></div>
  );
};

export default Spinner;
