import Icons from "@/assets/Icons";
import Button from "@/component/fragments/Button";
import { cn, sendIframeMessage } from "@/utils";
import { RavenModal } from "@ravenpay/raven-bank-ui";
import "~/reuseable/Modal.scss";

type ModalProps = React.ComponentProps<typeof RavenModal>;

type Props = Omit<ModalProps, "onBtnClick"> & {
  unsetHeight?: boolean;
};

const Modal = ({ className, unsetHeight, ...props }: Props) => (
  <RavenModal
    {...props}
    visble={props.visble ?? true}
    onBtnClick={() => {}}
    outerClose={props.outerClose ?? true}
    className={cn(className, "verification-modal", !unsetHeight && "content-height-55")}
  />
);

interface CloseProps {
  onClose(): void;
  onContinue(): void;
}

export const CloseAlertModal = ({ onClose, onContinue }: CloseProps) => {
  return (
    <div className="verification verification-close-screen">
      <div className="verification-wrapper">
        <Modal onClose={onClose} closeColor unsetHeight>
          <div className="verification-header">
            <h4 className="verification-title" style={{ maxWidth: "25ch", paddingTop: "1rem" }}>
              Are you sure you want to exit verification?
            </h4>
            <p>You will have to restart the verification process </p>
          </div>
          <div
            style={{ marginTop: "2.4rem", paddingBlock: "2rem 1rem" }}
            className="two-grid__buttons"
          >
            <Button outline onClick={onContinue}>
              Continue verification
            </Button>
            <Button color="error-dark" onClick={onClose}>
              Exit
            </Button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

interface ErrorModalProps {
  onClose(): void;
  error?: string;
}

export const ErrorModal = ({ onClose, error }: ErrorModalProps) => {
  return (
    <div className="verification verification-error-screen">
      <div className="verification-wrapper">
        <Modal onClose={onClose} closeColor unsetHeight>
          <div className="verification-header">
            <h4
              className="verification-title"
              style={{ marginBottom: "-0.6rem", paddingTop: "1rem" }}
            >
              Verification could not be started
            </h4>
            <p className="error-text">
              Unfortunately, we were unable to initiate the verification process at the moment
            </p>
          </div>
          <div className="warning-illustration">{Icons.Warning}</div>
          <div style={{ paddingBottom: "1rem" }} className="two-grid__buttons">
            <Button
              onClick={() => {
                sendIframeMessage({ data: { error }, type: "onSupport" });
                onClose();
              }}
            >
              Contact Support
            </Button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Modal;
