import ReactDOM from "react-dom/client";
import "@ravenpay/raven-bank-ui/src/styles/index.css";
import "./styles/index.scss";
import { AWSWrapper } from "./newApp";
import { FlowProvider } from "./context/FlowContext";
import { BrowserRouter } from "react-router-dom";
import { DisplayProvider } from "./context/DisplayContext";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <>
    <FlowProvider>
      <BrowserRouter>
        <DisplayProvider>
          <AWSWrapper>
            <App />
          </AWSWrapper>
        </DisplayProvider>
      </BrowserRouter>
    </FlowProvider>
  </>
);
