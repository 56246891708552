import { createContext, useContext, useReducer } from "react";

const initialState: BVNVerificationState = {
  bvn: null,
  file: null,
  step: 1,
};

const BVNContext = createContext(initialState);
const DispatchContext = createContext<React.Dispatch<Action>>((action) => {
  throw new Error("Context value called before BVNContextProvider was initialized");
});

type Action =
  | { type: "advance"; step?: number }
  | { type: "previous" }
  | { type: "addBVN"; payload: string }
  | { type: "addImage"; payload: string }
  | { type: "skipToPhone" }
  | { type: "onFetchError"; payload: OnFetchErrorPayload }
  | {
      /** use this to randomly update any state without creating a custom type */
      type: "*";
      payload: Partial<BVNVerificationState>;
    };

const bvnReducer = (state: BVNVerificationState, action: Action): BVNVerificationState => {
  const step = state.step + 1;

  switch (action.type) {
    case "advance":
      return { ...state, step: action?.step ?? step };
    case "previous":
      return { ...state, step: state.step - 1 };
    case "addBVN":
      return { ...state, bvn: action.payload, step, error: undefined };
    case "addImage":
      return { ...state, file: action.payload, step };
    case "*":
      return { ...state, ...action.payload };
    case "onFetchError":
      return {
        ...state,
        error: action.payload?.error,
        step: action.payload.redirectTo,
      };
    case "skipToPhone":
      return { ...state, step: 100 };
  }
};

export const BVNContextProvider = ({ children }: IChildren) => {
  const [state, dispatch] = useReducer(bvnReducer, initialState);

  return (
    <BVNContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </BVNContext.Provider>
  );
};

export const useBVNContext = () => {
  const state = useContext(BVNContext);
  const dispatch = useContext(DispatchContext);

  return { state, dispatch };
};
