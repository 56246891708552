import { AcceptedImages, IsDevMode } from "@/constants";
import { toast } from "@ravenpay/raven-bank-ui";
import clsx, { ClassValue } from "clsx";
import { Buffer } from "buffer";

export function cn(...inputs: ClassValue[]) {
  return clsx(inputs);
}

interface HDUArgs {
  file: File;
  handler: (file: File) => void;
}
export const handleDocumentUpload = (args: HDUArgs) => {
  const { handler, file } = args;

  if (!AcceptedImages.includes(file?.type)) {
    const [, name] = (file?.type ?? "").split("/");
    toast.error(`${name ?? file?.type} files are not accepted as an upload type`);
  } else {
    handler(file);
  }
};

export const createImgUrl = (file: NonNullable<IDVerificationState["file"]>): string => {
  if (typeof file === "string") return file;

  return URL.createObjectURL(file);
};

export const calculatePercentage = (num1: number, percent: number) => {
  const value = (num1 * percent) / 100;

  return value;
};

export const removeSpace = (str: string) => {
  const val = str.replace(/\s+/g, "");
  return val;
};

export const genCaptchaKeyAsync = async (param: unknown) => {
  try {
    if ("grecaptcha" in window) {
      const token = await (window.grecaptcha as any).execute(param, { action: "validate_captcha" });
      return token;
    } else {
      throw new Error("grecaptcha is undefined");
    }
  } catch (e) {
    return "error_string";
  }
};

/**
 * key: should be of type boolean
 */
export const handleDispatchClose = <T>(initialState: T, key: keyof T): T => {
  window.parent.postMessage({ type: "closeModal" }, "*");
  return { ...initialState, [key]: false };
};

export const assertVerificationMode = (method: unknown): VerificationMode => {
  if (typeof method !== "string") {
    throw new Error("Method is not valid");
  }

  if (method === "document") return "nin";

  if (!["nin", "bvn"].includes(method)) {
    throw new Error("Valid methods are nin or bvn");
  }

  return method as VerificationMode;
};

/***
 * Converts a dataUrl base64 image string into a File byte array
 * dataUrl example:
 * data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIsAAACLCAYAAABRGWr/AAAAAXNSR0IA...etc
 */
export function dataUrlToFile(dataUrl: FileUpload, filename: string): File | undefined {
  if (!dataUrl) return;

  if (typeof dataUrl === "string") {
    const arr = dataUrl.split(",");
    if (arr.length < 2) {
      return undefined;
    }
    const mimeArr = arr[0].match(/:(.*?);/);
    if (!mimeArr || mimeArr.length < 2) {
      return undefined;
    }
    const mime = mimeArr[1];
    const buff = Buffer.from(arr[1], "base64");
    return new File([buff], filename, { type: mime });
  }

  return dataUrl;
}

export const assertImageAsFile = (src: FileUpload) => {
  if (!src) {
    throw new Error("File does not exist");
  }

  if (typeof src === "string") {
    /**
     * @see
     * https://saturncloud.io/blog/creating-a-blob-from-a-base64-string-in-javascript/#:~:text=BLOB%20in%20JavaScript-,To%20convert%20a%20Base64%20string%20to%20a%20BLOB%20in%20JavaScript,creates%20a%20new%20BLOB%20object.
     */
    const binaryString = atob(src.split(",")[1]); // Binary data string
    const blob = new Blob([binaryString], { type: "image/png" }); // Create a BLOB object

    return blob;
  }
};

export const sendIframeMessage = ({ data, type }: { type: string; data: unknown }) => {
  window.parent.postMessage({ type, data }, "*");
};

export const assertMetaData = (metaData: string) => {
  return metaData;
  // return JSON.parse(metaData) as GetVerificationDetail;
};

/**
 * An util function that only logs things in dev mode
 */
export const log = (...args: unknown[]) => {
  if (IsDevMode) {
    console.log(...args);
  }
};

export const logError = (msg: any) => {
  log(JSON.stringify(msg), String(msg));
  return msg;
};

export const assertRef = (ref?: string | null) => {
  /**
   * 1296725023647088 -no meta_data
   * 9901403456124125 - meta_data
   */
  if (IsDevMode) return ref ?? "6403485178085100";

  if (ref) return ref;

  if (!ref) {
    throw new Error("Transaction reference is undefined");
  }
};

interface IsDarkCallBackArgs<T> {
  brightness: T;
  img: HTMLImageElement;
}

/**
 * Guide to function: https://stackoverflow.com/questions/13762864/image-brightness-detection-in-client-side-script
 */
type IsDarkCallBackFn<T = boolean> = (args: IsDarkCallBackArgs<T>) => void;

/**
 * Returns the color value of an image.
 * Guide to function https://jsfiddle.net/s7Wx2/
 */
export const getImageBrightness = (
  imageSrc: string,
  callBackFn: IsDarkCallBackFn<number>
): void => {
  const img = document.createElement("img");
  img.id = "imagebrightness";
  img.src = imageSrc;
  img.style.display = "none";
  document.body.appendChild(img);

  let colorSum = 0;

  img.onload = function () {
    try {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext("2d");

      if (ctx) {
        ctx.drawImage(img, 0, 0);

        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;

        for (let x = 0, len = data.length; x < len; x += 4) {
          const r = data[x];
          const g = data[x + 1];
          const b = data[x + 2];
          const avg = Math.floor((r + g + b) / 3);
          colorSum += avg;
        }

        const brightness = Math.floor(colorSum / (img.width * img.height));

        callBackFn({ brightness, img });
        if (document.body.contains(img)) {
          document.body.removeChild(img);
        }
      } else {
        console.error("Canvas context is null.");
      }
    } catch (error) {
      console.error("Error calculating brightness:", error);
    }
  };
};

/**
 * Check if an image is light or dark.
 * Guide to func: https://jsfiddle.net/s7Wx2/328/ .
 */
export function isItDark(imageSrc: string, callBackFn: IsDarkCallBackFn) {
  let fuzzy = 0.1;
  let img = document.createElement("img");
  img.id = "imagebrightness";
  img.src = imageSrc;
  img.style.display = "none";
  document.body.appendChild(img);

  let res: boolean = false;

  img.onload = function () {
    // create canvas
    let canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;

    let ctx = canvas.getContext("2d")!;
    ctx.drawImage(img, 0, 0);

    let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    let data = imageData.data;
    let r, g, b, max_rgb;
    let light = 0,
      dark = 0;

    for (let x = 0, len = data.length; x < len; x += 4) {
      r = data[x];
      g = data[x + 1];
      b = data[x + 2];

      max_rgb = Math.max(Math.max(r, g), b);
      if (max_rgb < 128) dark++;
      else light++;
    }

    let dl_diff = (light - dark) / (img.width * img.height);

    if (document.body.contains(img)) {
      document.body.removeChild(img);
    }

    if (dl_diff + fuzzy < 0) {
      callBackFn({ brightness: true, img });
    } else {
      callBackFn({ brightness: false, img });
    }
  };

  return res;
}

export function getQueryVariable(variable: string) {
  //"app=article&act=news_content&aid=160990"
  const query = window.location.search.substring(1);
  const vars = query.split("&");
  //[ 'app=article', 'act=news_content', 'aid=160990' ]
  for (let i = 0; i < vars.length; i++) {
    //[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
    const pair = vars[i].split("=");
    if (pair[0] === variable) {
      return pair[1];
    }
  }

  return false;
}
