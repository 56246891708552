import { createContext, useContext, useState } from "react";

const initialState: FlowState = {
  mode: "nin",
  mobileCallBack: null,
  metaData: null,
  color: "purple-light",
};

const FlowContext = createContext(initialState);

type TFlowUpdater = React.Dispatch<React.SetStateAction<FlowState>>;
const FlowUpdater = createContext<TFlowUpdater>((flow) => {
  throw new Error("Dispatch handler for flow modification was used before it was initialized");
});

export const FlowProvider = ({ children }: IChildren) => {
  const [flow, setFlow] = useState(initialState);

  return (
    <FlowContext.Provider value={flow}>
      <FlowUpdater.Provider value={setFlow}>{children}</FlowUpdater.Provider>
    </FlowContext.Provider>
  );
};

export const useFlowContext = () => {
  const flowState = useContext(FlowContext);
  const setFlowState = useContext(FlowUpdater);

  return { flowState, setFlowState };
};
